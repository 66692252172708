// must be set before lazyload import
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;

import 'lazysizes';
import 'lazysizes-optimumx';
// import inView from 'in-view';
// import onResize from 'on-resize';
import { $$ } from 'query-selector';

export default () => {
  const saveData =
    'connection' in navigator && navigator.connection.saveData === true;

  // Lazyload images
  function initLazySizes() {
    // Show lower resolution images if saveData
    if (saveData) {
      const images = $$('.lazyload');

      for (const image of images) {
        image.dataset.optimumx = 1;
      }
    }

    // Remove bg to avoid artefacts
    document.addEventListener('lazyloaded', e => {
      e.target.parentNode.classList.add('o-figure__wrapper--transparent');
    });

    lazySizes.init();
  }

  initLazySizes();

  // // Lazyload videos
  // const reducedMotion = window.matchMedia('(prefers-reduced-motion)').matches;

  // function showVideo(video) {
  //   video.classList.remove('video-lazyloading');
  //   video.classList.add('video-lazyloaded');

  //   // Remove bg to avoid artefacts
  //   video.parentNode.classList.add('o-figure__wrapper--transparent');
  // }

  // function initVideoLazyload(videos) {
  //   inView(
  //     videos,
  //     (video, visible) => {
  //       if (visible && video.classList.contains('video-lazyload')) {
  //         video.classList.remove('video-lazyload');
  //         video.classList.add('video-lazyloading');

  //         // Set sources and reload video with new sources
  //         const sources = $$('source', video);

  //         for (const source of sources) {
  //           source.setAttribute('src', source.dataset.src);
  //         }

  //         video.load();

  //         // If data saver or reduced motion, only set poster
  //         if (saveData || reducedMotion) {
  //           video.setAttribute(
  //             'poster',
  //             saveData ? video.dataset.posterlowres : video.dataset.poster
  //           );

  //           // remove sources to cancel load
  //           const sources = $$('source', video);

  //           for (const source of sources) {
  //             source.parentNode.removeChild(source);
  //           }

  //           video.load();

  //           // Fade in video
  //           showVideo(video);
  //         } else {
  //           // Fade in video as soon as first frame is loaded
  //           video.addEventListener('loadeddata', () => {
  //             showVideo(video);
  //           });

  //           video.addEventListener('canplaythrough', () => {
  //             showVideo(video);
  //             video.play();
  //           });
  //         }
  //       }
  //     },
  //     { offset: -0.2 }
  //   );
  // }

  // const videos = $$('.video-lazyload');

  // initVideoLazyload(videos);

  // onResize(() => {
  //   initVideoLazyload(videos);
  // });
};
