import bezier from 'bezier';
import onScroll from 'on-scroll';
import onResize from 'on-resize';
import vars from 'variables';
import { $, $$ } from 'query-selector';

// parallax([
//   {
//     reference: parent,
//     target: el,
//     top: true,
//     transform: [
//       {
//         type: 'translateX',
//         value: [100, 200],
//         unit: 'px',
//         ease: 'outIn',
//         origin: 'start'
//       }
//     ]
//   }
// ]);

export default elements => {
  const intersectionObserverSupport =
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
    'isIntersecting' in window.IntersectionObserverEntry.prototype;

  const easings = {
    in: bezier(0.25, 0.5, 0.5, 0.9),
    out: bezier(0.5, 0.1, 0.75, 0.5),
    inOut: bezier(0, 0.75, 1, 0.25)
  };

  let wideViewport = vars.browser.wide();

  function ease(type, value) {
    return easings[type](value);
  }

  function inView(rect) {
    return rect.top <= window.innerHeight && rect.bottom >= 0;
  }

  function relativeOffset(rect, top = false) {
    let offset = rect.top + rect.height;
    let height = window.innerHeight + rect.height;

    // element at top of page, e.g. hero image
    if (top) {
      offset = rect.bottom;
      height = rect.height;
    }

    let y = offset / height;
    y = Math.min(Math.max(y, 0), 1);

    return y;
  }

  function getPosition(el, force = false) {
    if (el.hasOwnProperty('isIntersecting') && !el.isIntersecting && !force)
      return;
    const rect = el.reference.getBoundingClientRect();

    el.inView = inView(rect);
    if (el.inView || force) el.relativeOffset = relativeOffset(rect, el.top);
  }

  function setPosition(el, force = false) {
    if (el.hasOwnProperty('isIntersecting') && !el.isIntersecting && !force)
      return;
    if (!el.inView && !force) return;

    let transformValues = '';

    for (const t of el.transform) {
      let offset = el.relativeOffset;

      if (t.ease) offset = ease(t.ease, offset);

      offset = Math.round(offset * 1000) / 1000;

      if (t.origin === 'start') {
        offset = 1 - offset; // 0 -- 1
      } else if (t.origin === 'end') {
        offset = offset * -1; // -1 -- 0
      } else {
        offset = 0.5 - offset; // -0.5 -- 0.5
      }

      let value = wideViewport ? t.value[1] : t.value[0];
      value *= offset;

      if (t.type.indexOf('scale') !== -1) value += 1; // scale origin === 1

      transformValues += `${t.type}(${value}${t.unit || ''})`;
    }

    el.target.style.setProperty('transform', transformValues);
  }

  function parallax(els, force = false) {
    // Skip if reference or target does not exits
    for (const el of els) {
      if (!el.reference || !el.target) return;
    }

    // get position
    for (const el of els) {
      getPosition(el, force);
    }

    // seperated for better perf

    // set position
    for (const el of els) {
      setPosition(el, force);
    }
  }

  function initParallax(els) {
    for (const el of els) {
      // Skip if reference or target does not exits
      if (!el.reference || !el.target) continue;

      // Add Intersection Observer for better perf
      // (only calculate position if el is intersecting)
      if (intersectionObserverSupport) {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach(entry => {
              if (
                entry.isIntersecting === true ||
                entry.intersectionRatio > 0
              ) {
                el.isIntersecting = true;
              } else {
                el.isIntersecting = false;
              }
            });
          },
          {
            rootMargin: '0% 0%'
          }
        );
        observer.observe(el.reference);
      }
    }

    // Set init position for all elements,
    // no matter if visible or not (to prevent jumps).
    parallax(els, true);
  }

  initParallax(elements);

  onScroll(() => {
    wideViewport = vars.browser.wide();
    parallax(elements);
  });

  onResize(() => {
    wideViewport = vars.browser.wide();
    parallax(elements);
  });
};
