import inView from 'in-view';
//import bezier from 'bezier';
import parallax from 'parallax';
import vars from 'variables';
import { $, $$ } from 'query-selector';

function inAnims() {
  const els = $$('.u-anim--in, .u-anim--in-stagger');
  if (!els) return;

  let count = 0;

  inView(
    els,
    (el, visible) => {
      if (visible) {
        el.classList.add('u-anim--play');
        if (
          count > 0 &&
          !el.classList.contains('u-anim--no-delay') &&
          !el.classList.contains('u-anim--in-stagger')
        )
          el.classList.add('u-anim--delay-' + count);

        count++;

        // Wait 1 frame
        window.requestAnimationFrame(() => {
          count--;
        });
      }
    },
    {
      offset: 0.1
    }
  );
}

function parallaxAnims() {
  if (vars.browser.legacy || vars.browser.reducedMotion) return;

  const els = $$('.js-parallax > *');
  if (!els) return;

  const plxEls = [];

  for (const el of els) {
    plxEls.push({
      reference: el,
      target: el,
      transform: [
        {
          type: 'translateY',
          value: [0, -7],
          unit: 'rem',
          ease: 'out',
          origin: 'end'
        }
      ]
    });
  }

  parallax(plxEls);
}

function lottieAnims() {
  let lottie = null;

  function createAnims(els = null, offset = 0.2) {
    let count = 0;

    inView(
      els,
      async (el, visible) => {
        if (visible) {
          if (lottie === null) {
            lottie = await import(/* webpackChunkName: 'lottie' */ 'lottie');
          }

          const json = el.dataset.json;
          if (!json) return;

          setTimeout(() => {
            lottie.loadAnimation({
              container: el,
              renderer: 'svg',
              loop: false,
              autoplay: true,
              path: json
            });
          }, count * 250);

          count++;

          // Wait 1 frame
          window.requestAnimationFrame(() => {
            count--;
          });
        }
      },
      {
        offset: offset
      }
    );
  }

  const icons = $$('.js-service-icon, .js-newsletter-icon');
  const map = $$('.js-map-anim');
  if (!icons && !map) return;

  createAnims(icons);
  createAnims(map, 0.66);
}

// only works well with tabular numbers, but those do not look good
// function numberAnims() {
//   const numberEl = $('.l-about-hero__number span span:first-child');

//   const value = parseFloat(numberEl.textContent.replace(',', '.'));
//   let i = 0;
//   let time = 0;
//   let duration = 3 * 60;
//   let interval = 3;

//   function countUp() {
//     if (time < duration) {
//       if (time % interval === 0) {
//         let fraction = bezier(0.25, 0.5, 0.5, 1)(time / duration);
//         let number = value * fraction;

//         number = Math.round(number * 10) / 10;

//         numberEl.textContent = number.toString().replace('.', ',');
//       }

//       time++;
//     } else {
//       numberEl.textContent = value.toString().replace('.', ',');
//     }

//     requestAnimationFrame(countUp);
//   }

//   requestAnimationFrame(countUp);
// }

export default () => {
  inAnims();
  parallaxAnims();
  lottieAnims();
  //numberAnims();
};
