import setFocus from 'set-focus';
import scrollTo from 'scroll-to';
import onResize from 'on-resize';
import onScroll from 'on-scroll';
import transitionEnd from 'transition-end';
import vars from 'variables';
import { $, $$ } from 'query-selector';

const header = $('.js-header');
const headerWrapper = $('.js-header-wrapper');
const footer = $('.js-footer');
const main = $('.js-main');
const navWrapper = $('.js-nav-wrapper');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const quickNav = $('.js-header-quicknav');
const menuButton = $('.js-header-menu-button');
const contactOpenButton = $('.js-header-contact-open-button');
const contact = $('.js-header-contact');
const contactCloseButton = $('.js-header-contact-close-button');

let navVisible = false;
let contactVisible = false;
let scrollPosition = 0;
let fixedHeader = false;
let headerOnlyScroll = false;
const quickNavThreshold = 200;
const headerThreshold = 70;

// make header + nav only elements in flow
function addHeaderOnlyScroll() {
  if (headerOnlyScroll) return;
  headerOnlyScroll = true;

  const bodyTop = document.body.getBoundingClientRect().top;
  const mainTop = main.getBoundingClientRect().top;
  const footerTop = footer.getBoundingClientRect().top;

  main.style.setProperty('position', 'fixed');
  footer.style.setProperty('position', 'fixed');
  main.style.setProperty('opacity', 0);
  footer.style.setProperty('opacity', 0);
  main.style.setProperty('width', '100%');
  footer.style.setProperty('width', '100%');
  main.style.setProperty('top', mainTop - bodyTop - scrollPosition + 'px');
  footer.style.setProperty('top', footerTop - bodyTop - scrollPosition + 'px');
  header.classList.remove('l-header--fixed-visible');
  window.scrollTo(0, 0); // fix jumps
}

function removeHeaderOnlyScroll() {
  if (!headerOnlyScroll) return;
  headerOnlyScroll = false;

  main.style.removeProperty('position');
  footer.style.removeProperty('position');
  main.style.removeProperty('width');
  footer.style.removeProperty('width');
  main.style.removeProperty('opacity');
  footer.style.removeProperty('opacity');
  main.style.removeProperty('top');
  footer.style.removeProperty('top');
  header.classList.add('l-header--fixed-visible');
  window.scrollTo(0, scrollPosition);
}

async function showNav() {
  navVisible = true;
  fixedHeader = true;
  if (!contactVisible) scrollPosition = window.pageYOffset; // if contactVisible, use older scroll position

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add('o-nav-icon--active');

  navWrapper.classList.add('c-nav__wrapper--active');
  navWrapper.classList.add('c-nav__wrapper--anim');

  await transitionEnd(navWrapper, 'opacity');
  addHeaderOnlyScroll();
}

async function hideNav() {
  navVisible = false;
  if (!contactVisible) removeHeaderOnlyScroll();

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove('o-nav-icon--active');

  navWrapper.classList.remove('c-nav__wrapper--active');
  await transitionEnd(navWrapper, 'opacity');
  navWrapper.classList.remove('c-nav__wrapper--anim');
}

function initNav() {
  navToggle.addEventListener('click', e => {
    navVisible ? hideNav() : showNav();
  });

  // Close nav if link is clicked
  // navWrapper.addEventListener('click', e => {
  //   if (navVisible && e.target.tagName === 'A') hideNav();
  // });
}

function showFixedHeader() {
  fixedHeader = true;
  header.classList.add('l-header--fixed');

  headerWrapper.style.setProperty('transform', 'translateY(-2rem)');
  headerWrapper.style.setProperty('opacity', '0');
  const forceLayout = headerWrapper.offsetWidth;
  headerWrapper.style.setProperty(
    'transition',
    `transform 400ms ${vars.ease.out}, opacity 200ms ${vars.ease.fade}`
  );
  headerWrapper.style.setProperty('transform', 'translateY(0%)');
  headerWrapper.style.setProperty('opacity', '1');
}

async function hideFixedHeader(animated = true) {
  fixedHeader = false;

  if (!animated) {
    header.classList.remove('l-header--fixed');
    headerWrapper.removeAttribute('style');
  } else {
    headerWrapper.style.setProperty(
      'transition',
      `transform 200ms ${vars.ease.out}, opacity 100ms ${vars.ease.fade}, visibility 0ms linear 100ms`
    );
    headerWrapper.style.setProperty('transform', 'translateY(-2rem)');
    headerWrapper.style.setProperty('opacity', '0');

    await transitionEnd(headerWrapper, 'opacity');

    header.classList.remove('l-header--fixed');
    headerWrapper.removeAttribute('style');
  }
}

function showQuickNav() {
  header.classList.add('l-header--quicknav-visible');
}

function hideQuickNav() {
  header.classList.remove('l-header--quicknav-visible');
}

function showContact() {
  contactVisible = true;
  header.classList.add('l-header--contact-visible');

  addHeaderOnlyScroll();

  setFocus(contactCloseButton); // not sure if actually an a11y improvement

  import(/* webpackChunkName: 'map' */ 'map').then(module =>
    module.default(true)
  );
}

function hideContact() {
  contactVisible = false;
  header.classList.remove('l-header--contact-visible');
  removeHeaderOnlyScroll();
}

function overlayPossible() {
  const hideQuickNavArea = $('.js-hide-quicknav-area');
  if (!hideQuickNavArea) return true;

  let offset = calcOffset();

  // better: use IntersectionObserver
  function calcOffset() {
    const bodyRect = document.body.getBoundingClientRect();
    const elRect = hideQuickNavArea.getBoundingClientRect();

    let top = elRect.top - bodyRect.top;
    const bottom = top + elRect.height;

    top -= 50; // ~ height of quicknav

    return { top, bottom };
  }

  onResize(() => {
    offset = calcOffset();
  });

  let noOverlay = true;

  if (window.pageYOffset > offset.top && window.pageYOffset < offset.bottom) {
    noOverlay = false;
  } else {
    noOverlay = true;
  }

  return noOverlay;
}

function initQuickNav() {
  menuButton.addEventListener('click', () => {
    scrollPosition = window.pageYOffset;
    showFixedHeader();

    if (!vars.browser.wide()) {
      showNav();
      setFocus(navToggle); // not sure if actually an a11y improvement
    } else {
      setFocus($('a', header)); // not sure if actually an a11y improvement
    }
  });

  contactOpenButton.addEventListener('click', () => {
    scrollPosition = window.pageYOffset;
    showContact();
    showFixedHeader();
  });

  contactCloseButton.addEventListener('click', () => {
    hideContact();
  });

  // show quicknav
  if (window.pageYOffset > quickNavThreshold && overlayPossible()) {
    showQuickNav();
  }

  onScroll(() => {
    if (contactVisible || navVisible) return;

    // show quicknav
    if (window.pageYOffset > quickNavThreshold && overlayPossible()) {
      showQuickNav();
    } else {
      hideQuickNav();
    }
  });
}

export default () => {
  initNav();
  initQuickNav();

  onScroll(() => {
    if (contactVisible || navVisible || !fixedHeader) return;

    // hide fixed header with threshold
    if (
      window.pageYOffset > scrollPosition + headerThreshold ||
      window.pageYOffset < scrollPosition - headerThreshold
    ) {
      hideFixedHeader();
    }
  });

  onResize(
    () => {
      hideFixedHeader(false);
      hideContact();
      hideNav();
    },
    { horizontalOnly: true }
  );
};
