// Prevent Safari 10.1 nomodule bug
if (window.scriptLoaded) throw new Error('Safari 10.1 nomodule bug');
window.scriptLoaded = true;

// Set Webpack public path for async modules
const src = document.querySelector('script[data-path]').getAttribute('src');
__webpack_public_path__ = src.substr(0, src.lastIndexOf('/') + 1);

// Polyfills
import 'promise-polyfill'; // only in legacy build, also to make import() work
import 'classlist-polyfill'; // only in legacy build
import(
  /* webpackChunkName: 'focus-visible-polyfill' */ 'focus-visible-polyfill'
); // not immediately necessary

// Modules
import initLazyload from 'lazyload';
import initNav from 'nav';
import initAnims from 'animation';
import initHomeHero from 'home-hero';

import { $ } from 'query-selector';

function init() {
  initLazyload();
  initNav();
  initAnims();
  initHomeHero();

  // async
  if ($('.js-b2c-hero')) {
    import(/* webpackChunkName: 'b2c-hero' */ 'b2c-hero').then(module =>
      module.default()
    );
  }

  if ($('.js-map')) {
    import(/* webpackChunkName: 'map' */ 'map').then(module =>
      module.default()
    );
  }

  if ($('.js-article-search')) {
    import(
      /* webpackChunkName: 'article-search' */ 'article-search'
    ).then(module => module.default());
  }

  if ($('.js-citation')) {
    import(/* webpackChunkName: 'citation' */ 'citation').then(module =>
      module.default()
    );
  }

  if ($('.js-form')) {
    import(/* webpackChunkName: 'form' */ 'form').then(module =>
      module.default()
    );
  }

  if ($('.js-subservices')) {
    import(/* webpackChunkName: 'subservices' */ 'subservices').then(module =>
      module.default()
    );
  }

  if ($('.js-social-links')) {
    import(/* webpackChunkName: 'social-links' */ 'social-links').then(module =>
      module.default()
    );
  }

  if ($('.js-video')) {
    import(/* webpackChunkName: 'video' */ 'video').then(module =>
      module.default()
    );
  }

  if ($('meta[name="analytics"]')) {
    import(/* webpackChunkName: 'analytics' */ 'analytics').then(module =>
      module.default()
    );
  }
}

init();
