import onResize from 'on-resize';
import { $ } from 'query-selector';

function initVideo(hero, intro, loop) {
  const heroAnimClass = 'l-home-hero--anim';
  const heroLoopClass = 'l-home-hero--loop';

  hero.classList.remove(heroAnimClass);
  hero.classList.remove(heroLoopClass);

  intro.pause();
  loop.pause();

  intro.currentTime = 0;
  loop.currentTime = 0;

  intro.play();

  intro.addEventListener('ended', () => {
    loop.play();
    hero.classList.add(heroLoopClass);
  });

  intro.addEventListener('timeupdate', e => {
    if (!hero.classList.contains(heroAnimClass) && intro.currentTime > 2.9) {
      hero.classList.add(heroAnimClass);
    }
  });
}

export default () => {
  const hero = $('.js-home-hero');
  if (!hero) return;

  const introSmall = $('figure:nth-child(1) > *:nth-child(1) > video', hero);
  const introLarge = $('figure:nth-child(1) > *:nth-child(2) > video', hero);
  const loopSmall = $('figure:nth-child(2) > *:nth-child(1) > video', hero);
  const loopLarge = $('figure:nth-child(2) > *:nth-child(2) > video', hero);
  let largePlaying = false;
  let smallPlaying = false;

  function initHero(hero) {
    if (window.matchMedia(`(min-width: ${720 / 16}em)`).matches) {
      if (!largePlaying) {
        largePlaying = true;
        initVideo(hero, introLarge, loopLarge);

        introSmall.pause();
        loopSmall.pause();
        smallPlaying = false;
      }
    } else {
      if (!smallPlaying) {
        smallPlaying = true;
        initVideo(hero, introSmall, loopSmall);

        introLarge.pause();
        loopLarge.pause();
        largePlaying = false;
      }
    }
  }

  initHero(hero);

  onResize(() => {
    initHero(hero);
  });
};
